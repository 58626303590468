<script setup>
  const props = defineProps({
    careerSite: { required: true, type: Object },
    mobility: { default: false, type: Boolean },
    subHeader: { default: false, type: Boolean }
  })

  const cover = ref(null)
  const apply = ref(false)

  watch(
    () => props.careerSite.cover,
    value => (cover.value = value ?? props.careerSite.employer?.cover),
    { immediate: true }
  )
</script>

<template>
  <header
    class="h-40 overflow-x-hidden bg-cover bg-center sm:h-60 lg:h-80"
    :style="{
      backgroundImage: cover?.url ? `url('${useStrapiMedia(cover.url)}')` : undefined
    }"
  >
    <div class="h-full bg-black/50">
      <div class="mx-auto flex h-full max-w-screen-xl flex-col items-center justify-center space-y-4 px-4 text-center sm:space-y-8">
        <h1 class="line-clamp-3 text-2xl font-bold text-white sm:text-3xl lg:text-4xl">{{ careerSite.employer.name }}</h1>
        <h2 class="!mt-3 font-special text-xl font-bold uppercase text-white" v-if="careerSite.employer.acronym">
          {{ careerSite.employer.acronym }}
        </h2>
        <NuxtLink :to="careerSite.employer.website">
          <NuxtImg
            class="w-12 rounded-full hover:cursor-pointer sm:w-20 lg:w-28"
            v-if="careerSite.employer.logo?.url"
            :src="useStrapiMedia(careerSite.employer.logo.url)"
            alt="employer.name"
          />
        </NuxtLink>
      </div>
    </div>
  </header>

  <section class="bg-zinc-100" v-if="subHeader && careerSite.introText">
    <div
      class="mx-auto flex max-w-screen-lg flex-col space-y-8 px-4 py-8 lg:flex-row lg:space-x-8 lg:space-y-0 lg:py-8 lg:text-lg xl:max-w-screen-xl"
    >
      <Markdown class="text-[#1f1f1f] marker:prose-ul:!text-[#1f1f1f]" :content="careerSite.introText" />
      <div class="flex items-center justify-center">
        <Button
          class="whitespace-nowrap border-2 bg-white !font-sans no-underline hover:shadow"
          v-if="!mobility"
          color="link"
          outline
          :style="{ borderColor: careerSite.primaryColor }"
          @click="apply = true"
          >Candidature spontanée</Button
        >
      </div>
    </div>
  </section>

  <ClientOnly>
    <PagesCareerApplyDialog :career-site="careerSite" :mobility="mobility" title="Candidature spontanée" :open="apply" @close="apply = false" />
  </ClientOnly>
</template>
